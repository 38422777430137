@tailwind base;
@tailwind components;
@tailwind utilities;

select#user-selection,
select#additional-collections-selection {
  width: 100%;
  height: 4em;
}

@layer base {
  /* https://alpinejs.dev/directives/cloak */
  [x-cloak] {
    display: none !important;
  }

  #detail-info {
    @apply bg-blue-50 my-4 p-2 rounded-sm border-gray-200 border;
  }

  a {
    @apply text-primary;

    &:hover {
      @apply text-primary-focus;
    }
  }

  a.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }

  .heading-1 {
    @apply text-primary text-4xl;
  }

  .heading-2 {
    @apply text-primary text-3xl;
  }

  .heading-3 {
    @apply text-primary text-2xl;
  }

  .heading-4 {
    @apply text-primary text-xl;
  }

  .flex-grow-5 {
    flex-grow: 0.5;
  }

  .errorlist {
    li {
      @apply flex bg-red-100 border border-red-300 my-4 px-4 py-3 rounded-md text-red-900 text-sm;
    }
  }

  .fixed-banner {
      background-color: #f3ff52;
      color: #2e2e2e;
      display: block;
      font-weight: bold;
      line-height: 35px;
      position: sticky;
      text-align: center;
      top: 0;
      z-index: 1000;
  }

  td {
    white-space: normal !important;
  }
}

/* spinner code, see https://loading.io/css/ */
.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 12px;
  height: 12px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 12px;
  height: 12px;
  border: 1px solid currentColor;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* spinner code end */

/* collection-description (contains markdown converted html) */
#collection-description {
  h1 {
    @apply text-2xl font-bold;
  }

  h2 {
    @apply text-xl font-bold;
  }

  h3 {
    @apply text-lg font-bold;
  }

  ul {
    @apply list-disc list-inside;
  }

  ol {
    @apply list-decimal list-inside;
  }
}

.diagnosis-picker {
.search-container {
  margin-bottom: 20px;
}

#search-input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.tree-toggle {
  cursor: pointer;
  width: 20px;
  display: inline-block;
}

.tree {
  margin: 20px 0 20px;
}

.tree > ul {
  padding-left: 0 !important;
}

.tree ul {
  list-style-type: none;
  padding-left: 20px;
}

.tree li {
  margin: 0;
  position: relative;
}

.diagnosis {
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 3px;
}

.diagnosis:hover {
  background-color: #f0f0f0;
}

.selected {
  background-color: #e6f3ff;
}

#selection {
  margin: 20px 0 20px 0;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #def;
}

.hidden {
  display: none;
}

.match {
  background-color: #fff3cd;
}

}


.quick-select {
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 12px;
}

.quick-select h3 {
  margin: 0 0 12px 0;
  color: #475569;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.quick-select-list {
  display: grid;
  gap: 8px;
}

.quick-select-item {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 14px;
  color: #1e293b;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.quick-select-item:hover {
  background: #f0f4f8;
}

.quick-select-item.quick-select-selected {
  background-color: #def;
}
