*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: Nunito, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

[multiple], textarea, select, [type="text"], [type="email"], [type="url"], [type="password"], [type="number"], [type="date"], [type="datetime-local"], [type="month"], [type="search"], [type="tel"], [type="time"], [type="week"] {
  -ms-appearance: none;
  appearance: none;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  border-width: 1px;
  border-color: #6b7280;
  border-radius: 0;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[multiple]:focus, textarea:focus, select:focus, [type="text"]:focus, [type="email"]:focus, [type="url"]:focus, [type="password"]:focus, [type="number"]:focus, [type="date"]:focus, [type="datetime-local"]:focus, [type="month"]:focus, [type="search"]:focus, [type="tel"]:focus, [type="time"]:focus, [type="week"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
  outline: 2px solid #0000;
}

input::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}

input::placeholder {
  color: #6b7280;
  opacity: 1;
}

textarea::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}

textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

::-webkit-datetime-edit {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-year-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-month-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-day-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-hour-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-minute-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-second-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-millisecond-field {
  padding-top: 0;
  padding-bottom: 0;
}

::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  color-adjust: exact;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  color-adjust: unset;
  padding-right: .75rem;
}

[type="checkbox"], [type="radio"] {
  -ms-appearance: none;
  appearance: none;
  color-adjust: exact;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #2563eb;
  --tw-shadow: 0 0 #0000;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #6b7280;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  padding: 0;
  display: inline-block;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus, [type="radio"]:focus {
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, );
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  outline: 2px solid #0000;
}

[type="checkbox"]:checked, [type="radio"]:checked {
  background-color: currentColor;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

[type="checkbox"]:checked:hover, [type="checkbox"]:checked:focus, [type="radio"]:checked:hover, [type="radio"]:checked:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

[type="checkbox"]:indeterminate:hover, [type="checkbox"]:indeterminate:focus {
  background-color: currentColor;
  border-color: #0000;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  font-size: unset;
  line-height: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px auto -webkit-focus-ring-color;
}

:root, [data-theme] {
  background-color: hsla(var(--b1) / var(--tw-bg-opacity, 1));
  color: hsla(var(--bc) / var(--tw-text-opacity, 1));
}

html {
  -webkit-tap-highlight-color: transparent;
}

:root {
  --p: 212 100% 51%;
  --pf: 212 100% 41%;
  --sf: 247 47% 35%;
  --af: 310 49% 42%;
  --nf: 217 92% 8%;
  --pc: 212 100% 90%;
  --sc: 247 100% 89%;
  --ac: 310 100% 90%;
  --nc: 217 100% 82%;
  --inc: 192 100% 16%;
  --suc: 182 100% 13%;
  --wac: 32 100% 17%;
  --erc: 0 100% 14%;
  --rounded-box: 1rem;
  --rounded-btn: .5rem;
  --rounded-badge: 1.9rem;
  --animation-btn: .25s;
  --animation-input: .2s;
  --btn-text-case: uppercase;
  --btn-focus-scale: .95;
  --border-btn: 1px;
  --tab-border: 1px;
  --tab-radius: .5rem;
  --s: 247 47% 43%;
  --a: 310 49% 52%;
  --n: 217 92% 10%;
  --b1: 0 0% 100%;
  --b2: 217 100% 97%;
  --b3: 219 44% 92%;
  --bc: 214 30% 32%;
  --in: 192 93% 78%;
  --su: 182 47% 66%;
  --wa: 32 62% 84%;
  --er: 0 63% 72%;
}

[x-cloak] {
  display: none !important;
}

#detail-info {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  border-radius: .125rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: .5rem;
}

a {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

a:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--pf, var(--p)) / var(--tw-text-opacity));
}

a.disabled {
  pointer-events: none;
  cursor: default;
  opacity: .5;
}

.heading-1 {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.heading-2 {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.heading-3 {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
  font-size: 1.5rem;
  line-height: 2rem;
}

.heading-4 {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.flex-grow-5 {
  flex-grow: .5;
}

.errorlist li {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
  border-radius: .375rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: .75rem 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: flex;
}

.fixed-banner {
  color: #2e2e2e;
  text-align: center;
  z-index: 1000;
  background-color: #f3ff52;
  font-weight: bold;
  line-height: 35px;
  display: block;
  position: sticky;
  top: 0;
}

td {
  white-space: normal !important;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.alert {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  border-radius: var(--rounded-box, 1rem);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  display: flex;
}

.alert > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

@media (width >= 768px) {
  .alert {
    flex-direction: row;
  }

  .alert > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
}

.alert > :where(*) {
  align-items: center;
  gap: .5rem;
  display: flex;
}

.avatar.placeholder > div {
  justify-content: center;
  align-items: center;
  display: flex;
}

.badge {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: hsl(var(--n) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  border-radius: var(--rounded-badge, 1.9rem);
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 1.25rem;
  padding-left: .563rem;
  padding-right: .563rem;
  font-size: .875rem;
  line-height: 1.25rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.breadcrumbs {
  max-width: 100%;
  padding-top: .5rem;
  padding-bottom: .5rem;
  overflow-x: auto;
}

.breadcrumbs > ul {
  white-space: nowrap;
  align-items: center;
  min-height: min-content;
  display: flex;
}

.breadcrumbs > ul > li {
  align-items: center;
  display: flex;
}

.breadcrumbs > ul > li > a {
  cursor: pointer;
  align-items: center;
  display: flex;
}

.breadcrumbs > ul > li > a:hover {
  text-decoration-line: underline;
}

.btn {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-color: #0000;
  border-color: hsl(var(--n) / var(--tw-border-opacity));
  text-align: center;
  border-radius: var(--rounded-btn, .5rem);
  text-transform: uppercase;
  text-transform: var(--btn-text-case, uppercase);
  border-width: var(--border-btn, 1px);
  animation: button-pop var(--animation-btn, .25s) ease-out;
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  flex-wrap: wrap;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1em;
  text-decoration-line: none;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.btn-disabled, .btn[disabled] {
  pointer-events: none;
}

.btn-square {
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn-circle {
  border-radius: 9999px;
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn.loading, .btn.loading:hover {
  pointer-events: none;
}

.btn.loading:before {
  content: "";
  border-width: 2px;
  border-color: #0000 currentColor currentColor #0000;
  border-radius: 9999px;
  width: 1rem;
  height: 1rem;
  margin-right: .5rem;
  animation: 2s linear infinite spin;
}

@media (prefers-reduced-motion: reduce) {
  .btn.loading:before {
    animation: 10s linear infinite spin;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.btn-group > input[type="radio"].btn {
  -ms-appearance: none;
  appearance: none;
}

.btn-group > input[type="radio"].btn:before {
  content: attr(data-title);
}

.card {
  border-radius: var(--rounded-box, 1rem);
  flex-direction: column;
  display: flex;
  position: relative;
  overflow: hidden;
}

.card:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.card-body {
  padding: var(--padding-card, 2rem);
  flex-direction: column;
  flex: auto;
  gap: .5rem;
  display: flex;
}

.card-body :where(p) {
  flex-grow: 1;
}

.card figure {
  justify-content: center;
  align-items: center;
  display: flex;
}

.card.image-full {
  display: grid;
}

.card.image-full:before {
  content: "";
  z-index: 10;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  opacity: .75;
  border-radius: var(--rounded-box, 1rem);
  position: relative;
}

.card.image-full:before, .card.image-full > * {
  grid-row-start: 1;
  grid-column-start: 1;
}

.card.image-full > figure img {
  object-fit: cover;
  height: 100%;
}

.card.image-full > .card-body {
  z-index: 20;
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
  position: relative;
}

.checkbox {
  --chkbg: var(--bc);
  --chkfg: var(--b1);
  cursor: pointer;
  -ms-appearance: none;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: .2;
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.divider {
  white-space: nowrap;
  flex-direction: row;
  align-self: stretch;
  align-items: center;
  height: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.divider:before, .divider:after {
  content: "";
  flex-grow: 1;
  width: 100%;
  height: .125rem;
}

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown > :focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.dropdown .dropdown-content {
  visibility: hidden;
  z-index: 50;
  opacity: 0;
  transform-origin: top;
  --tw-scale-x: .95;
  --tw-scale-y: .95;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
}

.dropdown-end .dropdown-content {
  right: 0;
}

.dropdown-left .dropdown-content {
  transform-origin: 100%;
  top: 0;
  bottom: auto;
  right: 100%;
}

.dropdown-right .dropdown-content {
  transform-origin: 0;
  top: 0;
  bottom: auto;
  left: 100%;
}

.dropdown-top .dropdown-content {
  transform-origin: bottom;
  top: auto;
  bottom: 100%;
}

.dropdown-end.dropdown-right .dropdown-content, .dropdown-end.dropdown-left .dropdown-content {
  top: auto;
  bottom: 0;
}

.dropdown.dropdown-open .dropdown-content, .dropdown.dropdown-hover:hover .dropdown-content, .dropdown:not(.dropdown-hover):focus .dropdown-content, .dropdown:not(.dropdown-hover):focus-within .dropdown-content {
  visibility: visible;
  opacity: 1;
}

.form-control {
  flex-direction: column;
  display: flex;
}

.label {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  padding: .5rem .25rem;
  display: flex;
}

.input {
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 1;
  height: 3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: .875rem;
  line-height: 2;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.input-group > *, .input-group > .input {
  border-radius: 0;
}

.link {
  cursor: pointer;
  text-decoration-line: underline;
}

.menu {
  flex-direction: column;
  display: flex;
}

.menu.horizontal {
  flex-direction: row;
  display: inline-flex;
}

.menu.horizontal :where(li) {
  flex-direction: row;
}

.menu :where(li) {
  flex-flow: column wrap;
  align-items: stretch;
  display: flex;
  position: relative;
}

.menu :where(li:not(.menu-title)) > :where(:not(ul)) {
  display: flex;
}

.menu :where(li:not(.disabled):not(.menu-title)) > :where(:not(ul)) {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline-offset: 2px;
  outline: 2px solid #0000;
  align-items: center;
}

.menu > :where(li > :not(ul):focus) {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.menu > :where(li.disabled > :not(ul):focus) {
  cursor: auto;
}

.menu > :where(li) :where(ul) {
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.menu > :where(li) > :where(ul) {
  top: initial;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  display: none;
  position: absolute;
  left: 100%;
}

.menu > :where(li:hover) > :where(ul), .menu > :where(li:focus) > :where(ul) {
  display: flex;
}

.modal {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  z-index: 999;
  background-color: hsl(var(--nf, var(--n)) / var(--tw-bg-opacity));
  --tw-bg-opacity: .4;
  overscroll-behavior: contain;
  justify-content: center;
  transition-property: transform, opacity;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: fixed;
  inset: 0;
  overflow-y: hidden;
}

:where(.modal) {
  align-items: center;
}

.modal-box {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-top-left-radius: var(--rounded-box, 1rem);
  border-top-right-radius: var(--rounded-box, 1rem);
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
  overscroll-behavior: contain;
  width: 91.6667%;
  max-width: 32rem;
  max-height: calc(100vh - 5em);
  padding: 1.5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  overflow-y: auto;
  box-shadow: 0 25px 50px -12px #00000040;
}

.modal-open, .modal:target, .modal-toggle:checked + .modal {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
}

.modal-toggle {
  -ms-appearance: none;
  appearance: none;
  opacity: 0;
  width: 0;
  height: 0;
  position: fixed;
}

.navbar {
  padding: var(--navbar-padding, .5rem);
  align-items: center;
  width: 100%;
  min-height: 4rem;
  display: flex;
}

:where(.navbar > *) {
  align-items: center;
  display: inline-flex;
}

.navbar-start {
  justify-content: flex-start;
  width: 50%;
}

.navbar-center {
  flex-shrink: 0;
}

.navbar-end {
  justify-content: flex-end;
  width: 50%;
}

.progress {
  -ms-appearance: none;
  appearance: none;
  border-radius: var(--rounded-box, 1rem);
  width: 100%;
  height: .5rem;
  position: relative;
  overflow: hidden;
}

.radio {
  --chkbg: var(--bc);
  cursor: pointer;
  -ms-appearance: none;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: .2;
  transition: background, box-shadow var(--animation-input, .2s) ease-in-out;
  border-radius: 9999px;
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.select {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-appearance: none;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  background-image: linear-gradient(45deg, #0000 50%, currentColor 50%), linear-gradient(135deg, currentColor 50%, #0000 50%);
  background-position: calc(100% - 20px) calc(1px + 50%), calc(100% - 16px) calc(1px + 50%);
  background-repeat: no-repeat;
  background-size: 4px 4px, 4px 4px;
  flex-shrink: 0;
  height: 3rem;
  min-height: 3rem;
  padding-left: 1rem;
  padding-right: 2.5rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 2;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: inline-flex;
}

.select-disabled, .select[disabled] {
  pointer-events: none;
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  --tw-text-opacity: .2;
}

.stats {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  border-radius: var(--rounded-box, 1rem);
  display: inline-grid;
}

:where(.stats) {
  grid-auto-flow: column;
  overflow-x: auto;
}

.stat {
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: .1;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 1rem;
  width: 100%;
  padding: 1rem 1.5rem;
  display: inline-grid;
}

.steps .step {
  text-align: center;
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
  place-items: center;
  min-width: 4rem;
  display: grid;
}

.swap {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  place-content: center;
  display: inline-grid;
  position: relative;
}

.swap > * {
  grid-row-start: 1;
  grid-column-start: 1;
  transition-property: transform, opacity;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.swap input {
  -ms-appearance: none;
  appearance: none;
}

.swap .swap-on, .swap .swap-indeterminate, .swap input:indeterminate ~ .swap-on, .swap input:checked ~ .swap-off, .swap.swap-active .swap-off, .swap input:indeterminate ~ .swap-off {
  opacity: 0;
}

.swap input:checked ~ .swap-on, .swap-active .swap-on, .swap input:indeterminate ~ .swap-indeterminate {
  opacity: 1;
}

.tabs {
  flex-wrap: wrap;
  align-items: flex-end;
  display: flex;
}

.tab {
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: center;
  --tab-padding: 1rem;
  --tw-text-opacity: .5;
  --tab-color: hsla(var(--bc) / var(--tw-text-opacity, 1));
  --tab-bg: hsla(var(--b1) / var(--tw-bg-opacity, 1));
  --tab-border-color: hsla(var(--b3) / var(--tw-bg-opacity, 1));
  color: var(--tab-color);
  padding-left: var(--tab-padding, 1rem);
  padding-right: var(--tab-padding, 1rem);
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  height: 2rem;
  font-size: .875rem;
  line-height: 2;
  display: inline-flex;
  position: relative;
}

.table {
  text-align: left;
  position: relative;
}

.table th:first-child {
  z-index: 11;
  position: sticky;
  left: 0;
}

.textarea {
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 0;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
  border-radius: var(--rounded-btn, .5rem);
  flex-shrink: 1;
  min-height: 3rem;
  padding: .5rem 1rem;
  font-size: .875rem;
  line-height: 2;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.toggle {
  --chkbg: hsla(var(--bc) / .2);
  --handleoffset: 1.5rem;
  cursor: pointer;
  -ms-appearance: none;
  appearance: none;
  border-width: 1px;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: .2;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
  border-radius: var(--rounded-badge, 1.9rem);
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transition: background, box-shadow var(--animation-input, .2s) ease-in-out;
  box-shadow: calc(var(--handleoffset) * -1) 0 0 2px hsl(var(--b1)) inset, 0 0 0 2px hsl(var(--b1)) inset;
  flex-shrink: 0;
  width: 3rem;
  height: 1.5rem;
}

.tooltip {
  --tooltip-offset: calc(100% + 1px + var(--tooltip-tail, 0px));
  text-align: center;
  --tooltip-tail: 3px;
  --tooltip-color: hsl(var(--n));
  --tooltip-text-color: hsl(var(--nc));
  --tooltip-tail-offset: calc(100% + 1px - var(--tooltip-tail));
  display: inline-block;
  position: relative;
}

.tooltip:before {
  pointer-events: none;
  content: attr(data-tip);
  top: auto;
  left: 50%;
  right: auto;
  bottom: var(--tooltip-offset);
  background-color: var(--tooltip-color);
  color: var(--tooltip-text-color);
  border-radius: .25rem;
  width: max-content;
  max-width: 20rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  position: absolute;
  transform: translateX(-50%);
}

.btn-outline .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nf, var(--n)) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
}

.btn-outline.btn-primary .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.btn-outline.btn-accent .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.btn-outline .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nf, var(--n)) / var(--tw-border-opacity));
  background-color: #0000;
}

.btn-outline.btn-primary .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
  background-color: #0000;
}

.btn-outline.btn-secondary .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity));
  background-color: #0000;
}

.btn-outline.btn-accent .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity));
  background-color: #0000;
}

.btn-outline.btn-info .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--in) / var(--tw-text-opacity));
  background-color: #0000;
}

.btn-outline.btn-success .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity));
  background-color: #0000;
}

.btn-outline.btn-warning .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wa) / var(--tw-text-opacity));
  background-color: #0000;
}

.btn-outline.btn-error .badge-outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--er) / var(--tw-text-opacity));
  background-color: #0000;
}

.btn-outline:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
}

.btn-outline:hover .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--nc) / var(--tw-text-opacity));
}

.btn-outline.btn-primary:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pc) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.btn-outline.btn-primary:hover .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf, var(--p)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sc) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary:hover .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf, var(--s)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.btn-outline.btn-accent:hover .badge {
  --tw-border-opacity: 1;
  border-color: hsl(var(--ac) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--ac) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity));
}

.btn-outline.btn-accent:hover .badge.outline {
  --tw-border-opacity: 1;
  border-color: hsl(var(--ac) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--af, var(--a)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.breadcrumbs > ul > li > a:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.breadcrumbs > ul > li > a:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.breadcrumbs > ul > li + :before {
  content: "";
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  opacity: .4;
  background-color: #0000;
  border-top: 1px solid;
  border-right: 1px solid;
  width: .375rem;
  height: .375rem;
  margin-left: .5rem;
  margin-right: .75rem;
  display: block;
}

.btn:active:hover, .btn:active:focus {
  transform: scale(var(--btn-focus-scale, .95));
  animation: none;
}

.btn:hover, .btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--nf, var(--n)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nf, var(--n)) / var(--tw-bg-opacity));
}

.btn:focus-visible {
  outline: 2px solid hsl(var(--nf));
  outline-offset: 2px;
}

.btn-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-primary:hover, .btn-primary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pf, var(--p)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf, var(--p)) / var(--tw-bg-opacity));
}

.btn-primary:focus-visible {
  outline: 2px solid hsl(var(--p));
}

.btn-secondary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--s) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--s) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.btn-secondary:hover, .btn-secondary.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sf, var(--s)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf, var(--s)) / var(--tw-bg-opacity));
}

.btn-secondary:focus-visible {
  outline: 2px solid hsl(var(--s));
}

.btn-error {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc, var(--nc)) / var(--tw-text-opacity));
}

.btn-error:hover, .btn-error.btn-active {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
}

.btn-error:focus-visible {
  outline: 2px solid hsl(var(--er));
}

.btn.glass:hover, .btn.glass.btn-active {
  --glass-opacity: 25%;
  --glass-border-opacity: 15%;
}

.btn.glass:focus-visible {
  outline: 2px solid 0 0 2px currentColor;
}

.btn-ghost {
  color: currentColor;
  background-color: #0000;
  border-width: 1px;
  border-color: #0000;
}

.btn-ghost:hover, .btn-ghost.btn-active {
  --tw-border-opacity: 0;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
}

.btn-ghost:focus-visible {
  outline: 2px solid 0 0 2px currentColor;
}

.btn-outline {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  background-color: #0000;
  border-color: currentColor;
}

.btn-outline:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--b1) / var(--tw-text-opacity));
}

.btn-outline.btn-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.btn-outline.btn-primary:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--pf, var(--p)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--pf, var(--p)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary {
  --tw-text-opacity: 1;
  color: hsl(var(--s) / var(--tw-text-opacity));
}

.btn-outline.btn-secondary:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--sf, var(--s)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--sf, var(--s)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--sc) / var(--tw-text-opacity));
}

.btn-outline.btn-accent {
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity));
}

.btn-outline.btn-accent:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--af, var(--a)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--af, var(--a)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.btn-outline.btn-success {
  --tw-text-opacity: 1;
  color: hsl(var(--su) / var(--tw-text-opacity));
}

.btn-outline.btn-success:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--su) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--suc, var(--nc)) / var(--tw-text-opacity));
}

.btn-outline.btn-info {
  --tw-text-opacity: 1;
  color: hsl(var(--in) / var(--tw-text-opacity));
}

.btn-outline.btn-info:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--in) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--inc, var(--nc)) / var(--tw-text-opacity));
}

.btn-outline.btn-warning {
  --tw-text-opacity: 1;
  color: hsl(var(--wa) / var(--tw-text-opacity));
}

.btn-outline.btn-warning:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--wa) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wac, var(--nc)) / var(--tw-text-opacity));
}

.btn-outline.btn-error {
  --tw-text-opacity: 1;
  color: hsl(var(--er) / var(--tw-text-opacity));
}

.btn-outline.btn-error:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--er) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc, var(--nc)) / var(--tw-text-opacity));
}

.btn-disabled, .btn-disabled:hover, .btn[disabled], .btn[disabled]:hover {
  --tw-border-opacity: 0;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.btn.loading.btn-square:before, .btn.loading.btn-circle:before {
  margin-right: 0;
}

.btn.loading.btn-xl:before, .btn.loading.btn-lg:before {
  width: 1.25rem;
  height: 1.25rem;
}

.btn.loading.btn-sm:before, .btn.loading.btn-xs:before {
  width: .75rem;
  height: .75rem;
}

.btn-group > .btn-active, .btn-group > input[type="radio"]:checked.btn {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.btn-group > input[type="radio"]:checked.btn:focus-visible, .btn-group > .btn-active:focus-visible {
  outline: 2px solid hsl(var(--p));
}

.btn-group > .btn:not(:first-of-type) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -1px;
}

.btn-group > .btn:not(:last-of-type) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@keyframes button-pop {
  0% {
    transform: scale(var(--btn-focus-scale, .95));
  }

  40% {
    transform: scale(1.02);
  }

  100% {
    transform: scale(1);
  }
}

.card:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.card.bordered {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity));
}

.card.compact .card-body {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.card-title {
  align-items: center;
  gap: .5rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.75rem;
  display: flex;
}

.checkbox:focus-visible {
  outline: 2px solid hsl(var(--bc));
  outline-offset: 2px;
}

.checkbox:checked, .checkbox[checked="true"] {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  animation: checkmark var(--animation-input, .2s) ease-in-out;
  background-repeat: no-repeat;
  background-image: linear-gradient(-45deg, transparent 65%, hsl(var(--chkbg)) 65.99%), linear-gradient(45deg, transparent 75%, hsl(var(--chkbg)) 75.99%), linear-gradient(-45deg, hsl(var(--chkbg)) 40%, transparent 40.99%), linear-gradient(45deg, hsl(var(--chkbg)) 30%, hsl(var(--chkfg)) 30.99%, hsl(var(--chkfg)) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(var(--chkfg)) 50%, hsl(var(--chkbg)) 50.99%);
}

.checkbox:indeterminate {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  animation: checkmark var(--animation-input, .2s) ease-in-out;
  background-repeat: no-repeat;
  background-image: linear-gradient(90deg, transparent 80%, hsl(var(--chkbg)) 80%), linear-gradient(-90deg, transparent 80%, hsl(var(--chkbg)) 80%), linear-gradient(0deg, hsl(var(--chkbg)) 43%, hsl(var(--chkfg)) 43%, hsl(var(--chkfg)) 57%, hsl(var(--chkbg)) 57%);
}

.checkbox:disabled {
  cursor: not-allowed;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  opacity: .2;
  border-color: #0000;
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }

  50% {
    background-position-y: -2px;
  }

  100% {
    background-position-y: 0;
  }
}

body[dir="rtl"] .checkbox {
  --chkbg: var(--bc);
  --chkfg: var(--b1);
}

body[dir="rtl"] .checkbox:checked, body[dir="rtl"] .checkbox[checked="true"] {
  background-image: linear-gradient(45deg, transparent 65%, hsl(var(--chkbg)) 65.99%), linear-gradient(-45deg, transparent 75%, hsl(var(--chkbg)) 75.99%), linear-gradient(45deg, hsl(var(--chkbg)) 40%, transparent 40.99%), linear-gradient(-45deg, hsl(var(--chkbg)) 30%, hsl(var(--chkfg)) 30.99%, hsl(var(--chkfg)) 40%, transparent 40.99%), linear-gradient(45deg, hsl(var(--chkfg)) 50%, hsl(var(--chkbg)) 50.99%);
}

.divider:before, .divider:after {
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: .1;
}

.divider:not(:empty) {
  gap: 1rem;
}

.drawer-toggle:focus-visible ~ .drawer-content .drawer-button.btn-primary {
  outline: 2px solid hsl(var(--p));
}

.drawer-toggle:focus-visible ~ .drawer-content .drawer-button.btn-secondary {
  outline: 2px solid hsl(var(--s));
}

.drawer-toggle:focus-visible ~ .drawer-content .drawer-button.btn-error {
  outline: 2px solid hsl(var(--er));
}

.drawer-toggle:focus-visible ~ .drawer-content .drawer-button.btn-ghost {
  outline: 2px solid;
}

.dropdown.dropdown-open .dropdown-content, .dropdown.dropdown-hover:hover .dropdown-content, .dropdown:focus .dropdown-content, .dropdown:focus-within .dropdown-content {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.label-text {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  font-size: .875rem;
  line-height: 1.25rem;
}

.label-text-alt {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  font-size: .75rem;
  line-height: 1rem;
}

.label a:hover {
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
}

.input-bordered {
  --tw-border-opacity: .2;
}

.input:focus {
  outline: 2px solid hsla(var(--bc) / .2);
  outline-offset: 2px;
}

.input-primary {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
}

.input-primary:focus {
  outline: 2px solid hsl(var(--p));
}

.input-disabled, .input[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  --tw-text-opacity: .2;
}

.input-disabled::-moz-placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.input-disabled::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.input[disabled]::-moz-placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.input[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.link:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.link:focus-visible {
  outline-offset: 2px;
  outline: 2px solid;
}

.menu.horizontal li.bordered > a, .menu.horizontal li.bordered > button, .menu.horizontal li.bordered > span {
  --tw-border-opacity: 1;
  border-bottom-width: 4px;
  border-left-width: 0;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
}

.menu[class*=" p-"] li > *, .menu[class^="p-"] li > * {
  border-radius: var(--rounded-btn, .5rem);
}

.menu :where(li.bordered > *) {
  --tw-border-opacity: 1;
  border-left-width: 4px;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
}

.menu :where(li) > :where(:not(ul)) {
  color: currentColor;
  gap: .75rem;
  padding: .75rem 1rem;
}

.menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul):focus), .menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul):hover) {
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: .1;
}

.menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul).active), .menu :where(li:not(.menu-title):not(:empty)) > :where(:not(ul):active) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.menu :where(li:empty) {
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: .1;
  height: 1px;
  margin: .5rem 1rem;
}

.menu li.disabled > * {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .2;
}

.menu li.disabled > :hover {
  background-color: #0000;
}

.menu li.hover-bordered a {
  border-color: #0000;
  border-left-width: 4px;
}

.menu li.hover-bordered a:hover {
  --tw-border-opacity: 1;
  border-color: hsl(var(--p) / var(--tw-border-opacity));
}

.menu.compact li > a, .menu.compact li > span {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.menu .menu-title > * {
  color: hsl(var(--bc) / var(--tw-text-opacity));
  --tw-text-opacity: .4;
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1rem;
}

.menu :where(li:not(.disabled)) > :where(:not(ul)) {
  outline-offset: 2px;
  outline: 2px solid #0000;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.menu > :where(li:first-child), .menu > :where(li:first-child) > :where(:not(ul)) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.menu > :where(li:last-child), .menu > :where(li:last-child) > :where(:not(ul)) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.menu > :where(li) > :where(ul) :where(li) {
  white-space: nowrap;
  width: 100%;
}

.menu > :where(li) > :where(ul) :where(li) :where(ul) {
  padding-left: 1rem;
}

.menu > :where(li) > :where(ul) :where(li) > :where(:not(ul)) {
  white-space: nowrap;
  width: 100%;
}

.menu > :where(li) > :where(ul) > :where(li:first-child), .menu > :where(li) > :where(ul) > :where(li:first-child) > :where(:not(ul)) {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: unset;
}

.menu > :where(li) > :where(ul) > :where(li:last-child), .menu > :where(li) > :where(ul) > :where(li:last-child) > :where(:not(ul)) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.mockup-phone .display {
  border-radius: 40px;
  margin-top: -25px;
  overflow: hidden;
}

.modal-open .modal-box, .modal-toggle:checked + .modal .modal-box, .modal:target .modal-box {
  --tw-translate-y: 0px;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.progress::-moz-progress-bar {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
}

.progress-primary::-moz-progress-bar {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
}

.progress:indeterminate:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  content: "";
  border-radius: var(--rounded-box, 1rem);
  width: 33.3333%;
  animation: 5s ease-in-out infinite progress-loading;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -40%;
}

.progress-primary:indeterminate:after {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
}

.progress::-webkit-progress-bar {
  background-color: hsl(var(--n) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
  border-radius: var(--rounded-box, 1rem);
}

.progress::-webkit-progress-value {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--nf, var(--n)) / var(--tw-bg-opacity));
  border-radius: var(--rounded-box, 1rem);
}

.progress-primary::-webkit-progress-value {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
}

@keyframes progress-loading {
  50% {
    left: 107%;
  }
}

.radio:focus-visible {
  outline: 2px solid hsl(var(--bc));
  outline-offset: 2px;
}

.radio:checked {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  animation: radiomark var(--animation-input, .2s) ease-in-out;
  box-shadow: 0 0 0 4px hsl(var(--b1)) inset, 0 0 0 4px hsl(var(--b1)) inset;
}

.radio:disabled {
  cursor: not-allowed;
  opacity: .2;
}

@keyframes radiomark {
  0% {
    box-shadow: 0 0 0 12px hsl(var(--b1)) inset, 0 0 0 12px hsl(var(--b1)) inset;
  }

  50% {
    box-shadow: 0 0 0 3px hsl(var(--b1)) inset, 0 0 0 3px hsl(var(--b1)) inset;
  }

  100% {
    box-shadow: 0 0 0 4px hsl(var(--b1)) inset, 0 0 0 4px hsl(var(--b1)) inset;
  }
}

@keyframes rating-pop {
  0% {
    transform: translateY(-.125em);
  }

  40% {
    transform: translateY(-.125em);
  }

  100% {
    transform: translateY(0);
  }
}

.select-bordered {
  --tw-border-opacity: .2;
}

.select:focus {
  outline: 2px solid hsla(var(--bc) / .2);
  outline-offset: 2px;
}

.select-disabled::-moz-placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.select-disabled::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.select[disabled]::-moz-placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.select[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.select-multiple, .select[multiple], .select[size].select:not([size="1"]) {
  background-image: none;
  padding-right: 1rem;
}

:where(.stats) > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
}

.steps .step:before {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  content: "";
  grid-row-start: 1;
  grid-column-start: 1;
  width: 100%;
  height: .5rem;
  margin-left: -100%;
  top: 0;
}

.steps .step:after {
  content: counter(step);
  counter-increment: step;
  z-index: 1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--bc) / var(--tw-text-opacity));
  border-radius: 9999px;
  grid-row-start: 1;
  grid-column-start: 1;
  place-self: center;
  place-items: center;
  width: 2rem;
  height: 2rem;
  display: grid;
  position: relative;
}

.steps .step:first-child:before {
  content: none;
}

.steps .step[data-content]:after {
  content: attr(data-content);
}

.tab:hover {
  --tw-text-opacity: 1;
}

.tab.tab-active {
  border-color: hsl(var(--bc) / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  --tw-text-opacity: 1;
}

.tab:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.tab:focus-visible {
  outline-offset: -3px;
  outline: 2px solid;
}

.tab:focus-visible.tab-lifted {
  border-bottom-right-radius: var(--tab-radius, .5rem);
  border-bottom-left-radius: var(--tab-radius, .5rem);
}

.table :where(th, td) {
  white-space: nowrap;
  vertical-align: middle;
  padding: 1rem;
}

.table tr.active th, .table tr.active td, .table tr.active:nth-child(2n) th, .table tr.active:nth-child(2n) td, .table tr.hover:hover th, .table tr.hover:hover td, .table tr.hover:nth-child(2n):hover th, .table tr.hover:nth-child(2n):hover td {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b3, var(--b2)) / var(--tw-bg-opacity));
}

.table:where(:not(.table-zebra)) :where(thead, tbody, tfoot) :where(tr:not(:last-child) :where(th, td)) {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity));
}

.table :where(thead, tfoot) :where(th, td) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  text-transform: uppercase;
  font-size: .75rem;
  font-weight: 700;
  line-height: 1rem;
}

.table :where(:first-child) :where(:first-child) :where(th, td):first-child {
  border-top-left-radius: .5rem;
}

.table :where(:first-child) :where(:first-child) :where(th, td):last-child {
  border-top-right-radius: .5rem;
}

.table :where(:last-child) :where(:last-child) :where(th, td):first-child {
  border-bottom-left-radius: .5rem;
}

.table :where(:last-child) :where(:last-child) :where(th, td):last-child {
  border-bottom-right-radius: .5rem;
}

.table :where(tbody th, tbody td) {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
}

.table-zebra tbody tr:nth-child(2n) th, .table-zebra tbody tr:nth-child(2n) td {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.textarea:focus {
  outline: 2px solid hsla(var(--bc) / .2);
  outline-offset: 2px;
}

.textarea-disabled, .textarea[disabled] {
  cursor: not-allowed;
  --tw-border-opacity: 1;
  border-color: hsl(var(--b2, var(--b1)) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
  --tw-text-opacity: .2;
}

.textarea-disabled::-moz-placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.textarea-disabled::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.textarea[disabled]::-moz-placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.textarea[disabled]::placeholder {
  color: hsl(var(--bc) / var(--tw-placeholder-opacity));
  --tw-placeholder-opacity: .2;
}

.toggle:focus-visible {
  outline: 2px solid hsl(var(--bc));
  outline-offset: 2px;
}

.toggle:checked, .toggle[checked="true"] {
  --chkbg: hsl(var(--bc));
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  box-shadow: var(--handleoffset) 0 0 2px hsl(var(--b1)) inset, 0 0 0 2px hsl(var(--b1)) inset;
}

[dir="rtl"] .toggle:checked, [dir="rtl"] .toggle[checked="true"] {
  box-shadow: calc(var(--handleoffset) * 1) 0 0 2px hsl(var(--b1)) inset, 0 0 0 2px hsl(var(--b1)) inset;
}

.toggle:indeterminate {
  --chkbg: hsl(var(--bc));
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  box-shadow: calc(var(--handleoffset) / 2) 0 0 2px hsl(var(--b1)) inset, calc(var(--handleoffset) / -2) 0 0 2px hsl(var(--b1)) inset, 0 0 0 2px hsl(var(--b1)) inset;
}

[dir="rtl"] .toggle:indeterminate {
  box-shadow: calc(var(--handleoffset) / 2) 0 0 2px hsl(var(--b1)) inset, calc(var(--handleoffset) / -2) 0 0 2px hsl(var(--b1)) inset, 0 0 0 2px hsl(var(--b1)) inset;
}

.toggle-primary:focus-visible {
  outline: 2px solid hsl(var(--p));
}

.toggle-primary:checked, .toggle-primary[checked="true"] {
  --chkbg: hsl(var(--p));
  border-color: hsl(var(--p) / var(--tw-border-opacity));
  --tw-border-opacity: .1;
  --tw-bg-opacity: 1;
  background-color: hsl(var(--p) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--pc) / var(--tw-text-opacity));
}

.toggle:disabled {
  cursor: not-allowed;
  background-color: hsl(var(--bc) / var(--tw-bg-opacity));
  --tw-bg-opacity: .2;
  border-color: #0000;
}

.tooltip:before, .tooltip:after {
  opacity: 0;
  transition: color .2s cubic-bezier(.4, 0, .2, 1) .1s, background-color .2s cubic-bezier(.4, 0, .2, 1) .1s, border-color .2s cubic-bezier(.4, 0, .2, 1) .1s, text-decoration-color .2s cubic-bezier(.4, 0, .2, 1) .1s, fill .2s cubic-bezier(.4, 0, .2, 1) .1s, stroke .2s cubic-bezier(.4, 0, .2, 1) .1s, opacity .2s cubic-bezier(.4, 0, .2, 1) .1s, box-shadow .2s cubic-bezier(.4, 0, .2, 1) .1s, transform .2s cubic-bezier(.4, 0, .2, 1) .1s, filter .2s cubic-bezier(.4, 0, .2, 1) .1s, -webkit-backdrop-filter .2s cubic-bezier(.4, 0, .2, 1) .1s, backdrop-filter .2s cubic-bezier(.4, 0, .2, 1) .1s, -webkit-text-decoration-color .2s cubic-bezier(.4, 0, .2, 1) .1s, -webkit-backdrop-filter .2s cubic-bezier(.4, 0, .2, 1) .1s;
}

.tooltip:after {
  content: "";
  border-style: solid;
  border-width: var(--tooltip-tail, 0);
  border-color: var(--tooltip-color) transparent transparent transparent;
  top: auto;
  left: 50%;
  right: auto;
  bottom: var(--tooltip-tail-offset);
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  transform: translateX(-50%);
}

.tooltip.tooltip-open:before, .tooltip.tooltip-open:after, .tooltip:hover:before, .tooltip:hover:after {
  opacity: 1;
  transition-delay: 75ms;
}

.rounded-box {
  border-radius: var(--rounded-box, 1rem);
}

.btn-xs {
  height: 1.5rem;
  min-height: 1.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .75rem;
}

.btn-sm {
  height: 2rem;
  min-height: 2rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
}

.btn-square:where(.btn-xs) {
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

.btn-square:where(.btn-sm) {
  width: 2rem;
  height: 2rem;
  padding: 0;
}

.btn-square:where(.btn-md) {
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn-square:where(.btn-lg) {
  width: 4rem;
  height: 4rem;
  padding: 0;
}

.btn-circle:where(.btn-xs) {
  border-radius: 9999px;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
}

.btn-circle:where(.btn-sm) {
  border-radius: 9999px;
  width: 2rem;
  height: 2rem;
  padding: 0;
}

.btn-circle:where(.btn-md) {
  border-radius: 9999px;
  width: 3rem;
  height: 3rem;
  padding: 0;
}

.btn-circle:where(.btn-lg) {
  border-radius: 9999px;
  width: 4rem;
  height: 4rem;
  padding: 0;
}

.input-sm {
  height: 2rem;
  padding-left: .75rem;
  padding-right: .75rem;
  font-size: .875rem;
  line-height: 2rem;
}

.menu-horizontal {
  flex-direction: row;
  display: inline-flex;
}

.menu-horizontal :where(li) {
  flex-direction: row;
}

.menu-horizontal > :where(li) > :where(ul) {
  top: 100%;
  left: initial;
}

.steps-horizontal .step {
  text-align: center;
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-template-columns: repeat(1, minmax(0, 1fr));
  place-items: center;
  display: grid;
}

.steps-vertical .step {
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  display: grid;
}

.alert-info {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--in) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--inc, var(--nc)) / var(--tw-text-opacity));
}

.alert-success {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--su) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--suc, var(--nc)) / var(--tw-text-opacity));
}

.alert-warning {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--wa) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--wac, var(--nc)) / var(--tw-text-opacity));
}

.alert-error {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--er) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc, var(--nc)) / var(--tw-text-opacity));
}

.badge-accent {
  --tw-border-opacity: 1;
  border-color: hsl(var(--a) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--a) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--ac) / var(--tw-text-opacity));
}

.badge-outline.badge-accent {
  --tw-text-opacity: 1;
  color: hsl(var(--a) / var(--tw-text-opacity));
}

.card-compact .card-body {
  padding: 1rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.card-compact .card-title {
  margin-bottom: .25rem;
}

.card-normal .card-body {
  padding: var(--padding-card, 2rem);
  font-size: 1rem;
  line-height: 1.5rem;
}

.card-normal .card-title {
  margin-bottom: .75rem;
}

.menu-horizontal :where(li.bordered > *) {
  border-bottom-width: 4px;
  border-left-width: 0;
}

.menu-compact :where(li > *) {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.menu-horizontal > :where(li:first-child), .menu-horizontal > :where(li:first-child) > :where(:not(ul)) {
  border-top-left-radius: inherit;
  border-top-right-radius: unset;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: inherit;
}

.menu-horizontal > :where(li:last-child), .menu-horizontal > :where(li:last-child) > :where(:not(ul)) {
  border-top-left-radius: unset;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: unset;
}

.modal-bottom :where(.modal-box) {
  --tw-translate-y: 2.5rem;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
  max-width: none;
}

.modal-middle :where(.modal-box) {
  --tw-translate-y: 0px;
  --tw-scale-x: .9;
  --tw-scale-y: .9;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-bottom-left-radius: var(--rounded-box, 1rem);
  border-bottom-right-radius: var(--rounded-box, 1rem);
  width: 91.6667%;
  max-width: 32rem;
}

.steps-horizontal .step {
  grid-template-rows: 40px 1fr;
  grid-template-columns: auto;
  min-width: 4rem;
}

.steps-horizontal .step:before {
  --tw-translate-y: 0px;
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  content: "";
  width: 100%;
  height: .5rem;
  margin-left: -100%;
}

.steps-vertical .step {
  grid-template-rows: auto;
  grid-template-columns: 40px 1fr;
  justify-items: start;
  gap: .5rem;
  min-height: 4rem;
}

.steps-vertical .step:before {
  --tw-translate-y: -50%;
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  width: .5rem;
  height: 100%;
  margin-left: 50%;
}

.table-compact :where(th, td) {
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.visible {
  visibility: visible;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.top-6 {
  top: 1.5rem;
}

.left-1\/2 {
  left: 50%;
}

.top-1\/2 {
  top: 50%;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.left-0 {
  left: 0;
}

.z-20 {
  z-index: 20;
}

.z-10 {
  z-index: 10;
}

.order-2 {
  order: 2;
}

.order-1 {
  order: 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.my-0\.5 {
  margin-top: .125rem;
  margin-bottom: .125rem;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.ml-2 {
  margin-left: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-px {
  margin-top: 1px;
}

.mt-0 {
  margin-top: 0;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.ml-1 {
  margin-left: .25rem;
}

.-mr-1 {
  margin-right: -.25rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.-ml-1 {
  margin-left: -.25rem;
}

.mr-3 {
  margin-right: .75rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-4 {
  height: 1rem;
}

.h-12 {
  height: 3rem;
}

.h-auto {
  height: auto;
}

.h-2\/5 {
  height: 40%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.h-8 {
  height: 2rem;
}

.h-10 {
  height: 2.5rem;
}

.h-1\/2 {
  height: 50%;
}

.max-h-full {
  max-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.w-5 {
  width: 1.25rem;
}

.w-full {
  width: 100%;
}

.w-6 {
  width: 1.5rem;
}

.w-96 {
  width: 24rem;
}

.w-4 {
  width: 1rem;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-3\/4 {
  width: 75%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-28 {
  width: 7rem;
}

.w-56 {
  width: 14rem;
}

.w-1\/2 {
  width: 50%;
}

.w-auto {
  width: auto;
}

.w-52 {
  width: 13rem;
}

.w-1 {
  width: .25rem;
}

.w-10 {
  width: 2.5rem;
}

.w-3 {
  width: .75rem;
}

.min-w-0 {
  min-width: 0;
}

.min-w-full {
  min-width: 100%;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-max {
  max-width: max-content;
}

.flex-1 {
  flex: 1;
}

.flex-none {
  flex: none;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-4 {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-none {
  grid-template-columns: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.gap-4 {
  gap: 1rem;
}

.gap-2 {
  gap: .5rem;
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.75rem * var(--tw-space-x-reverse));
  margin-left: calc(.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.-space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1px * var(--tw-space-y-reverse));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
}

.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-b-md {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-tl-md {
  border-top-left-radius: .375rem;
}

.rounded-tr-md {
  border-top-right-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-t {
  border-top-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-t-0 {
  border-top-width: 0;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}

.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}

.border-transparent {
  border-color: #0000;
}

.border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 224, 71, var(--tw-border-opacity));
}

.border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}

.border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}

.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}

.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}

.bg-base-100 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b1) / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}

.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 249, 195, var(--tw-bg-opacity));
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}

.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 252, 231, var(--tw-bg-opacity));
}

.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}

.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}

.bg-base-200 {
  --tw-bg-opacity: 1;
  background-color: hsl(var(--b2, var(--b1)) / var(--tw-bg-opacity));
}

.bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}

.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 252, 232, var(--tw-bg-opacity));
}

.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(22, 163, 74, var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.fill-current {
  fill: currentColor;
}

.stroke-info {
  stroke: hsl(var(--in));
}

.stroke-current {
  stroke: currentColor;
}

.p-8 {
  padding: 2rem;
}

.p-4 {
  padding: 1rem;
}

.p-2 {
  padding: .5rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-0 {
  padding: 0;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-semibold {
  font-weight: 600;
}

.font-medium {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.uppercase {
  text-transform: uppercase;
}

.normal-case {
  text-transform: none;
}

.italic {
  font-style: italic;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.tracking-wider {
  letter-spacing: .05em;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}

.text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}

.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: hsl(var(--p) / var(--tw-text-opacity));
}

.text-info {
  --tw-text-opacity: 1;
  color: hsl(var(--in) / var(--tw-text-opacity));
}

.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}

.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}

.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgba(133, 77, 14, var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}

.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}

.text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(22, 101, 52, var(--tw-text-opacity));
}

.text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgba(113, 63, 18, var(--tw-text-opacity));
}

.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}

.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}

.text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(250, 204, 21, var(--tw-text-opacity));
}

.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgba(161, 98, 7, var(--tw-text-opacity));
}

.opacity-75 {
  opacity: .75;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-25 {
  opacity: .25;
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px #0000001a, 0 8px 10px -6px #0000001a;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}

.ring-opacity-5 {
  --tw-ring-opacity: .05;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.duration-200 {
  transition-duration: .2s;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

select#user-selection, select#additional-collections-selection {
  width: 100%;
  height: 4em;
}

.lds-ring, .lds-ring div {
  box-sizing: border-box;
}

.lds-ring {
  width: 12px;
  height: 12px;
  display: inline-block;
  position: relative;
}

.lds-ring div {
  box-sizing: border-box;
  border: 1px solid #0000;
  border-top-color: currentColor;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-ring;
  display: block;
  position: absolute;
}

.lds-ring div:first-child {
  animation-delay: -.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

#collection-description h1 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
}

#collection-description h2 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

#collection-description h3 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
}

#collection-description ul {
  list-style-type: disc;
  list-style-position: inside;
}

#collection-description ol {
  list-style-type: decimal;
  list-style-position: inside;
}

.diagnosis-picker .search-container {
  margin-bottom: 20px;
}

.diagnosis-picker #search-input {
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  padding: 8px;
  font-size: 16px;
}

.diagnosis-picker .tree-toggle {
  cursor: pointer;
  width: 20px;
  display: inline-block;
}

.diagnosis-picker .tree {
  margin: 20px 0;
}

.diagnosis-picker .tree > ul {
  padding-left: 0 !important;
}

.diagnosis-picker .tree ul {
  padding-left: 20px;
  list-style-type: none;
}

.diagnosis-picker .tree li {
  margin: 0;
  position: relative;
}

.diagnosis-picker .diagnosis {
  cursor: pointer;
  border-radius: 3px;
  padding: 2px 5px;
}

.diagnosis-picker .diagnosis:hover {
  background-color: #f0f0f0;
}

.diagnosis-picker .selected {
  background-color: #e6f3ff;
}

.diagnosis-picker #selection {
  background-color: #def;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 20px 0;
  padding: 15px;
}

.diagnosis-picker .hidden {
  display: none;
}

.diagnosis-picker .match {
  background-color: #fff3cd;
}

.quick-select {
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 12px;
}

.quick-select h3 {
  color: #475569;
  text-transform: uppercase;
  letter-spacing: .05em;
  margin: 0 0 12px;
  font-size: 14px;
}

.quick-select-list {
  gap: 8px;
  display: grid;
}

.quick-select-item {
  color: #1e293b;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  background: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  padding: 8px 12px;
  font-size: 14px;
  transition: all .2s;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 2px #0000000d;
}

.quick-select-item:hover {
  background: #f0f4f8;
}

.quick-select-item.quick-select-selected {
  background-color: #def;
}

.focus-within\:ring-2:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-within\:ring-inset:focus-within {
  --tw-ring-inset: inset;
}

.focus-within\:ring-indigo-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}

.focus-within\:ring-indigo-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
}

.focus-within\:ring-offset-2:focus-within {
  --tw-ring-offset-width: 2px;
}

.hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}

.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}

.hover\:bg-indigo-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}

.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.hover\:bg-transparent:hover {
  background-color: #0000;
}

.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(21, 128, 61, var(--tw-bg-opacity));
}

.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}

.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}

.hover\:text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover\:opacity-75:hover {
  opacity: .75;
}

.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}

.focus\:ring-green-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(34, 197, 94, var(--tw-ring-opacity));
}

.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}

.focus\:ring-offset-gray-100:focus {
  --tw-ring-offset-color: #f3f4f6;
}

.group:hover .group-hover\:bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}

@media (width >= 640px) {
  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:h-screen {
    height: 100vh;
  }

  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-95 {
    --tw-scale-x: .95;
    --tw-scale-y: .95;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .sm\:rounded-md {
    border-radius: .375rem;
  }

  .sm\:border-0 {
    border-width: 0;
  }

  .sm\:border-l {
    border-left-width: 1px;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:pb-0 {
    padding-bottom: 0;
  }

  .sm\:pb-16 {
    padding-bottom: 4rem;
  }

  .sm\:align-middle {
    vertical-align: middle;
  }

  .sm\:text-sm {
    font-size: .875rem;
    line-height: 1.25rem;
  }
}

@media (width >= 768px) {
  .md\:flex {
    display: flex;
  }
}

@media (width >= 1024px) {
  .lg\:bottom-0 {
    bottom: 0;
  }

  .lg\:top-auto {
    top: auto;
  }

  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-1 {
    height: .25rem;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:flex-1 {
    flex: 1;
  }

  .lg\:rounded-none {
    border-radius: 0;
  }

  .lg\:border-0 {
    border-width: 0;
  }

  .lg\:border-t {
    border-top-width: 1px;
  }

  .lg\:border-b {
    border-bottom-width: 1px;
  }

  .lg\:border-l {
    border-left-width: 1px;
  }

  .lg\:border-r {
    border-right-width: 1px;
  }

  .lg\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:pl-9 {
    padding-left: 2.25rem;
  }
}
/*# sourceMappingURL=styles.css.map */
